/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

const QuickLinks: FC = () => {
  const [Tenantlist, setTenantlist] = useState([])
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  
  function get_Tenant() {
    fetch(BASE_API_URL + "/MultiTenant_get_Tenant/",
      {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        response.json()
          .then((result) => {
            setTenantlist(result)
            console.log("result", result)
          })
      });
  }
  const [head, sethead] = useState('Select Tenant')
  
  useEffect(() => {
    get_Tenant()
    var obj = JSON.parse(localStorage.getItem("selected_tenant") || '{}')
    sethead(obj.selectedtenant)
    
  }, [])

  interface permision {
    menuname: string;
    display: unknown;
  }
  const [menuspermision, setmenupermision] = useState<permision[]>([])
  const itemss = menuspermision.map(permission => permission);
  const [allmenum, SetallMenus] = useState([])

  function selelected_tenant(selected_ten: any) {
    console.log(selected_ten)
    localStorage.setItem("selected_tenant", JSON.stringify({
      selectedtenant: selected_ten.split(" ")[0],
      TenantID: selected_ten.split(" ")[2]
    }))
    console.log(selected_ten.split(" ")[0], selected_ten.split(" ")[1])
    var obj = JSON.parse(localStorage.getItem("login") || '{}')
    obj.userdetail.subdomain = selected_ten.split(" ")[0]
    obj.userdetail.id = selected_ten.split(" ")[1]
    
    localStorage.setItem("login", JSON.stringify({
      login: true,
      userdetail: obj.userdetail
    }))
    window.location.reload()
    
    
  }


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10 '
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`, height: "50px" }}
      >
        <h3 className='text-white fw-bold mb-3'>Select Tenat</h3>
      </div>
      <div className='row g-0'>
        <select className="form-select mb-1 gfg border rounded-pill border-dark mt-4 mb=5" defaultValue="Select Tenant" onChange={(e) => selelected_tenant(e.target.value)} aria-label="Select Role" required>
          {head?
          <option > {head} </option>
          :
          <option > Select Tenant </option>
          }
          {Tenantlist.map((role, index) => (
            <option key={index} value={`${role["subdomain"]} ${[role['id']]} ${role['TenantID']}`} >{role["subdomain"]}</option>
          ))}
        </select>

       {/* <div className='col-6'>
        <a
          href='#'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
        >
          <KTSVG
            path='/media/icons/duotune/finance/fin009.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Accounting</span>
          <span className='fs-7 text-gray-400'>eCommerce</span>
        </a>
      </div> */}

        {/* <div className='col-6'>
        <a
          href='#'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com010.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Administration</span>
          <span className='fs-7 text-gray-400'>Console</span>
        </a>
      </div> */}

        {/* <div className='col-6'>
        <a href='#' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
          <KTSVG
            path='/media/icons/duotune/abstract/abs042.svg'}
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Projects</span>
          <span className='fs-7 text-gray-400'>Pending Tasks</span>
        </a>
      </div> */}

        {/* <div className='col-6'>
        <a href='#' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
          <KTSVG
            path='/media/icons/duotune/finance/fin006.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Customers</span>
          <span className='fs-7 text-gray-400'>Latest cases</span>
        </a>
      </div> */}
      </div>

      <div className='py-2 text-center border-top' >
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
      </Link>
    </div>
    {/* <div className='py-2 text-center border-top'>
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
        View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div> */}
    </div>
  )
};
export { QuickLinks }
