/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import {
    toAbsoluteUrl,
    defaultMessages,
    defaultUserInfos,
    MessageModel,
    UserInfoModel,
    messageFromClient,
} from '../../helpers'

import TypingEffect from '../../../app/pages/QAModel/typingeffect'
import { inherits } from 'util'
type Props = {
    isDrawer?: boolean;
}

const bufferMessages = defaultMessages

let lnk = ''
export function linklist(lnklist: any) {
    lnk = lnklist
    console.log("lnklist", lnklist);
}
const BASE_API_URL = process.env.REACT_APP_API_URL;
const ChatInner_with_docoment: FC<Props> = ({ isDrawer = false }) => {
    const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messages, setMessages] = useState(bufferMessages)
    const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)

    const scrollRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => {
                const { current } = scrollRef;
                if (current) {
                    const { scrollHeight, clientHeight } = current;
                    const offset = 45;
                    current.scrollTop = scrollHeight - clientHeight + offset;
                }
            }, 0); // Delay of 0 milliseconds
        }
    };

    scrollToBottom()

    interface ItemType {
        user: string;
        bot: string;
    }

    const [conversationlist, setconversationlist] = useState<ItemType[]>([])
    const [loader, setloader] = useState(false)
    const BASE_API_URL = process.env.REACT_APP_API_URL;
    const [answ, setansw] = useState("")
    function submit_question() {
        setloader(true)
        setMessage("")
        var data1 = { message: message, link: lnk }
        fetch(BASE_API_URL + "/chat_with_document/",
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data1)
            })
            .then((response) => {
                response.json()
                    .then((result) => {
                        console.log(result)
                        // setansw(result)

                        scrollToBottom()
                        const newConversation = { "user": message, "bot": result };
                        setconversationlist(prevList => [...prevList, newConversation]);
                        setloader(false)
                    })
            });
    }

    const sendMessage = () => {
        const newMessage: MessageModel = {
            user: 2,
            type: 'out',
            text: message,
            time: 'Just now',
        }

        bufferMessages.push(newMessage)
        setMessages(bufferMessages)
        toggleChatUpdateFlat(!chatUpdateFlag)
        setMessage('')
        setTimeout(() => {
            bufferMessages.push(messageFromClient)
            setMessages(() => bufferMessages)
            toggleChatUpdateFlat((flag) => !flag)
        }, 1000)
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            submit_question()
        }
    }

    return (
        <div
            className='card-body'
            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
        >
            <div
                className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
                data-kt-element='conversationlist'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies={
                    isDrawer
                        ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                        : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                }
                data-kt-scroll-wrappers={
                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                }
                data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                ref={scrollRef}
            >
                {/* <>{answ}</> */}
                {conversationlist.map((msg, index) => (
                    <div className=''>
                        <div className='d-flex align-items-center mb-2 justify-content-start'>
                            <div className='symbol symbol-35px symbol-circle '>
                                <img src={toAbsoluteUrl('/media/avatars/150-15.jpg')} alt='' />
                            </div>
                            <div className='ms-3'>
                                <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                >
                                    You
                                </a>
                                {/* <span className='text-muted fs-7 mb-1'>{message.time}</span> */}
                            </div>
                        </div>
                        <div className={clsx('p-5 rounded', `bg-light-info`, 'text-dark fw-bold mw-lg-400px', `text-start`)}>
                            {msg.user}
                        </div>
                        <div className='d-flex align-items-center mb-2 justify-content-end'>
                            <div className='me-3'>
                                {/* <span className='text-muted fs-7 mb-1'>{message.time}</span> */}
                                <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                >
                                    Bot
                                </a>
                            </div>
                            <div className='symbol  symbol-35px symbol-circle '>
                                <img src={toAbsoluteUrl('/media/avatars/150-2.jpg')} alt='' />
                            </div>
                        </div>

                        {/* {msg.bot} */}

                        <pre className='p-5 rounded bg-light-primary text-dark mw-lg-600px w-100 chat_bot_pre' style={{ fontFamily: "inherit" }}>
                            <TypingEffect text={msg.bot} speed={35} />
                        </pre>


                        {/* <div className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end typing-demo'>{msg.bot}</div> */}
                    </div>

                ))}
                {/* <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> */}
                {loader ?
                    <>
                        <div className=" loader">
                            {/* <span></span>
                            <span></span> */}
                        </div>
                    </>

                    :
                    ""
                }

                {/* {messages.map((message, index) => {
                    const userInfo = userInfos[message.user]
                    const state = message.type === 'in' ? 'info' : 'primary'
                    const templateAttr = {}
                    if (message.template) {
                        Object.defineProperty(templateAttr, 'data-kt-element', {
                            value: `template-${message.type}`,
                        })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'in' ? 'start' : 'end'
                        } mb-10`
                    return (
                        <div
                            key={`message${index}`}
                            className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                            {...templateAttr}
                        >
                            <div
                                className={clsx(
                                    'd-flex flex-column align-items',
                                    `align-items-${message.type === 'in' ? 'start' : 'end'}`
                                )}
                            >
                                <div className='d-flex align-items-center mb-2'>
                                    {message.type === 'in' ? (
                                        <>
                                            <div className='symbol symbol-35px symbol-circle '>
                                                <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} />
                                            </div>
                                            <div className='ms-3'>
                                                <a
                                                    href='#'
                                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                                >
                                                    {userInfo.name}
                                                </a>
                                                <span className='text-muted fs-7 mb-1'>{message.time}</span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='me-3'>
                                                <span className='text-muted fs-7 mb-1'>{message.time}</span>
                                                <a
                                                    href='#'
                                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                                >
                                                    You
                                                </a>
                                            </div>
                                            <div className='symbol  symbol-35px symbol-circle '>
                                                <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div
                                    className={clsx(
                                        'p-5 rounded',
                                        `bg-light-${state}`,
                                        'text-dark fw-bold mw-lg-400px',
                                        `text-${message.type === 'in' ? 'start' : 'end'}`
                                    )}
                                    data-kt-element='message-text'
                                    dangerouslySetInnerHTML={{ __html: message.text }}
                                ></div>
                            </div>
                        </div>
                    )
                })} */}
            </div>

            <div
                className='card-footer pt-4 w-450px'
                // id="kt_chat_messenger_footer"
                id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                style={{ position: "fixed", bottom: "0px" }}
            >
                <textarea
                    className='form-control form-control-flush mb-3'
                    rows={1}
                    data-kt-element='input'
                    placeholder='Type a message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={onEnterPress}
                ></textarea>

                <div className='d-flex flex-stack'>
                    <div className='d-flex align-items-center me-2'>
                        {/* <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button> */}
                    </div>
                    <button
                        className='btn btn-primary'
                        type='button'
                        data-kt-element='send'
                        onClick={submit_question}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    )
}

export { ChatInner_with_docoment }
