/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
// import {shallowEqual, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
// import {UserModel} from '../../../../app/modules/auth/models/UserModel'
// import {RootState} from '../../../../setup'
import { Languages } from './Languages'
import { runSaga } from 'redux-saga'
// import { date } from 'yup'
import { toAbsoluteUrl } from '../../../helpers'
function logout() {
  localStorage.removeItem("login");
  localStorage.removeItem("token");
  localStorage.removeItem('selected_tenant')
  localStorage.clear()
  window.location.reload();
}

const HeaderUserMenu: FC = () => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [email, setEmail] = useState('')
  const [userpic, setuserpic] = useState('')
  const [username, setusername] = useState('')
  const [role, setRole] = useState('')
  const [userType, setuserType] = useState('')
  const [subdom, setsubdom] = useState('')
  const [Localtimeformate, setLocaltimeformate] = useState('')
  const [office_location, setoffice_location] = useState('')

  const [isSuperUser, setisSuperUser] = useState(false)

  useEffect(() => {
    var store = localStorage.getItem("login")
    if (store !== null) {
      var obj = JSON.parse(localStorage.getItem("login") || "{}")
      setFirstname(obj.userdetail.first_name)
      setlastname(obj.userdetail.last_name)
      setEmail(obj.userdetail.email)
      setuserpic(obj.userdetail.profile_pic)
      setusername(obj.userdetail.username)
      setuserType(obj.userdetail.userType)
      setRole(obj.userdetail.roles)
      setLocaltimeformate(obj.userdetail.localesTimeFormate)
      setoffice_location(obj.userdetail.office_location)
      setsubdom(obj.userdetail.subdomain)
      setisSuperUser(obj.userdetail.is_superuser)
    }

    // var obj = JSON.parse(localStorage.getItem("token") || '{}')
    // var data = { token: obj.access }
    // fetch('http://127.0.0.1:8000/ProfileAPI/', {
    //   method: "POST",
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(data)
    // }).then((response) => {
    //   response.json()
    //     .then((result) => {
    //       console.log(result)
    //       if (result[0].id) {
    //           localStorage.setItem("login", JSON.stringify({
    //           login: true,
    //           id: result[0].id,
    //           username: result[0].username,
    //           first_name: result[0].first_name,
    //           last_name: result[0].last_name,
    //           email: result[0].email,
    //           role: result[0].role,
    //           date_joined: result[0].date_joined,
    //           is_active: result[0].is_active,
    //           is_staff: result[0].is_staff,
    //           is_superuser: result[0].is_superuser,
    //           visibility: result[0].visibility,
    //           aproved_by: result[0].aproved_by,
    //           office_location: result[0].office_location,
    //           brandname : result[0].brandname,
    //           outsidelogo : result[0].outsidelogo,
    //           insidelogo : result[0].insidelogo,
    //           titlelogo : result[0].titlelogo,
    //         }))
    //       }

    //     })
    // });
  }, [])
  // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (

    <>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >


        <div className='px-3 d-flex align-items-center '>
          <div className='card px-3'>
            {userpic ?
              <div className=''>
                <img src={userpic} alt="Preview" className='card-img-top ' />
              </div> :
              <div className=''>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='WORKELEVATE' className='card-img-top' />
              </div>
            }

            <div className='d-flex flex-column align-items-center mt-5'>
              <div className='fw-bolder  fs-5'>
                {firstname} {lastname}
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
              </div>
              <a href={`mailto:${email}`} className='fw-bold text-prinary text-hover-primary fs-7'>
                {email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        {/* <div className='menu-item px-5'>
          <Link to={'/crafted/account'} className='menu-link px-5'>
            Account
          </Link>
        </div> */}
        {isSuperUser ?
          <div className='menu-item px-5 ms-5 '>
            <span className='menu-text text-gray-600 mt-5'>Supter User</span>
            <span className='menu-badge'>
              <span className='badge badge-light-warning fw-bolder ms-3 fs-7'><i className="bi-stars text-warning"></i></span>
            </span>
          </div>
          :
          <>
            {userType != "member" &&
              <div className='menu-item px-5 ms-5 mt-5 d-flex justify-content-between'>
                <span className='menu-text text-gray-600'>User Type:</span>
                <span className='menu-badge'>
                  <span className='badge badge-light-dark fw-bolder ms-3 fs-7 text-capitalize text-gray-600'>{userType}</span>
                </span>
              </div>
            }
          </>


        }
        <div className='separator my-2'></div>
        {userType != "Tenant" &&
          <div className='menu-item px-5 ms-5 d-flex justify-content-between'>
            <span className='menu-text text-gray-600'>Role:</span>
            <span className='menu-badge'>
              <span className='badge badge-light-dark fw-bolder ms-3 fs-7 text-capitalize text-gray-600'>{role}</span>
            </span>
          </div>
        }
        <div className='separator my-2'></div>
        <div className='menu-item px-5 ms-5 d-flex justify-content-between'>
          <span className='menu-text text-gray-600'>Domain:</span>
          <span className='menu-badge'>
            <span className='badge badge-light-dark fw-bolder ms-3 fs-7 text-capitalize text-gray-600'>{subdom}</span>
          </span>
        </div>
        <div className='separator my-2'></div>
        {/* <div
          className='menu-item px-5'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='left-start'
          data-kt-menu-flip='bottom'
        >
          <a href='#' className='menu-link px-5' >
            <span className='menu-title'>My Subscription</span>
            <span className='menu-arrow'></span>
          </a>

          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Referrals
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Billing
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Payments
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex flex-stack px-5'>
                Statements
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='View your statements'
                ></i>
              </a>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-3'>
              <div className='menu-content px-3'>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-30px h-20px'
                    type='checkbox'
                    value='1'
                    defaultChecked={true}
                    name='notifications'
                  />
                  <span className='form-check-label text-muted fs-7'>Notifications</span>
                </label>
              </div>
            </div>
          </div>
        </div> */}

        <div className='separator my-2'></div>
        <div className='menu-item px-5 ms-5 d-flex justify-content-between'>
          <span className='menu-text text-gray-600'>Time Farmate:</span>
          <span className='menu-badge'>
            <span className='badge badge-light-dark fw-bolder ms-3 fs-7 text-capitalize text-gray-600'>{Localtimeformate}</span>
          </span>
        </div>

        <div className='separator my-2'></div>

        {/* <Languages /> */}

        <div className='menu-item px-5 my-1 mb-5'>
          <Link to='/crafted/account/settings' className='menu-link px-5 fw-bolder btn btn-light'>
            Account Settings
          </Link>
        </div>

        <div className=' px-5 text-end'>
          <button onClick={logout} className='menu-link p-3'>
            Sign Out
          </button>
        </div>
      </div>
    </>

  )
}

export { HeaderUserMenu }
