import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
// import { requestPassword } from '../redux/AuthCRUD'
import "./forgetpassword.css"



const initialValues = {
  email: 'jayprakash.mishra@workelevate.ai',
}
const forgotPasswordSchema = Yup.object().shape({

  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})
const BASE_API_URL = process.env.REACT_APP_API_URL;
// let webdomain = "http://127.0.0.1:8000"

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [Email_submited, set_Email_submited] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      set_email_for_verfied(values.email)
      var data1 = { email: values.email }
      fetch(BASE_API_URL + "/MultiTenant_Forget_password/",
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data1)
        })
        .then((response) => {
          response.json()
            .then((result) => {
              console.log(result.status)
              if (result.status === 200) {
                set_Email_submited(true)
                setHasErrors(false)
                countdown()
              }
              if (result.status === 400) {
                setHasErrors(true)
                setStatus('The login detail is incorrect')
              }
              setLoading(false)
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
            })
        });
    },
  })

  const [email_for_verfied, set_email_for_verfied] = useState('')
  const [OTP, set_OTP] = useState('')
  const [OTP_verified, set_OTP_verified] = useState<boolean | undefined>(undefined)

  function verify_OTP() {
    var data1 = { email: email_for_verfied, OTP: OTP }
    fetch(BASE_API_URL + "/MultiTenant_Verity_OTP/",
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data1)
      })
      .then((response) => {
        response.json()
          .then((result) => {
            console.log(result)
            setHasErrors(undefined)
            if (result.status === 200) {
              set_OTP_verified(true)
            }
            else if (result.status === 400) {
              set_OTP_verified(false)
            }

            // setHasErrors(false)
            // setLoading(false)
          })

      });
  }

  function Generate_OTP() {
    if (Newpassword === confirmassword) {
      set_OTP("")
      var data1 = { email: email_for_verfied }
      fetch(BASE_API_URL + "/MultiTenant_Forget_password/",
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data1)
        })
        .then((response) => {
          response.json()
            .then((result) => {
              // console.log(result)
              if (result.status === 200) {
                set_Email_submited(true)
                setHasErrors(false)

              }
              if (result.status === 400) {
                setHasErrors(true)
              }
            })
        });
    } else {
      console.log("password missmatch")
    }
  }

  const [Newpassword, setNewpassword] = useState("")
  const [confirmassword, setconfirmpassword] = useState("")
  const [passwordchanged, setpasswordchanged] = useState<boolean | undefined>(undefined)
  function Reset_password() {
    console.log(Newpassword.length && confirmassword.length)
    if (Newpassword === confirmassword) {
      var data1 = { email: email_for_verfied, paswd: confirmassword }
      fetch(BASE_API_URL + "/MultiTenant_change_password/",
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data1)
        })
        .then((response) => {
          response.json()
            .then((result) => {
              console.log(result)
              if (result.status === 200) {
                setpasswordchanged(true)
                setHasErrors(undefined)
              }
            })
        });
    } else {
      setpasswordchanged(false)
    }
  }

  // const myTimeout = setTimeout(myGreeting, 5000);
  const [counter, setcounter] = useState<number>()
  function countdown() {
    var count = 60;
    function greet() {
      setcounter(count)
      count--
      if (count === 0) {
        clearInterval(myInterval);
      }
    }
    const myInterval = setInterval(greet, 1000);
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to get your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {OTP_verified === true && (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>
              OTP Verified Successfully
            </div>
          </div>
        )}
        {OTP_verified === false && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Given OTP Missmatch
            </div>
          </div>
        )}

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>OTP has been sent in your Email. {email_for_verfied}</div>
          </div>
        )}

        {passwordchanged === true && (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>
              Password has been Changed successfully.
            </div>
          </div>
        )}
        {passwordchanged === false && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              New Password and Confirm Password Missmatch
            </div>
          </div>
        )}
        {/* end::Title */}
        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            disabled={Email_submited}
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap pb-lg-0 justify-content-around'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={Email_submited}
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}

      </form>
      {Email_submited ?
        <>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
            <input type='text' value={OTP} placeholder='OTP' autoComplete='off' disabled={OTP_verified} onChange={(e) => set_OTP(e.target.value)} className='form-control form-control-lg form-control-solid h-10 w-16' />
          </div>

          <div className='d-flex flex-wrap pb-lg-0 mb-5 justify-content-between'>
            <button type='submit' id='kt_password_reset_submit ' disabled={OTP_verified} onClick={verify_OTP} className='btn btn-lg btn-primary fw-bolder me-4' >
              <span className='indicator-label '>Submit OTP</span>
            </button>
            {OTP_verified ? "" :
              <>
                {counter !== 1 ?
                  <span className='indicator-label'>Remaing {counter}</span>
                  :
                  ""
                }
              </>
            }
            {OTP_verified ? "" :
              <>
                {counter === 1 ?
                  <button type='submit' id='kt_password_reset_submit' onClick={Generate_OTP} className='btn btn-lg btn-primary fw-bolder me-4' >
                    <span className='indicator-label'>Genrate OTP</span>
                  </button>
                  :
                  ""
                }
              </>
            }
          </div>
        </>
        :
        ""
      }
      {OTP_verified ?
        <>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
            <input type='password' placeholder='New Password' disabled={passwordchanged} autoComplete='off' onChange={(e) => setNewpassword(e.target.value)} className='form-control form-control-lg form-control-solid h-10 w-16' />
          </div>

          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
            <input type='password' placeholder='Confirm Password' disabled={passwordchanged} autoComplete='off' onChange={(e) => setconfirmpassword(e.target.value)} className='form-control form-control-lg form-control-solid h-10 w-16' />
          </div>

          <div className='d-flex flex-wrap pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' onClick={Reset_password} className='btn btn-lg btn-primary fw-bolder me-4' >
              <span className='indicator-label'>Change Password</span>
            </button>
            {passwordchanged ?
              <>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    className='btn btn-lg btn btn-primary fw-bolder'
                  >
                    Move To Login
                  </button>
                </Link>{' '}
              </>

              :
              ""}

          </div>
        </>
        :
        ""
      }

    </>
  )
}
