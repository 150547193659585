/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
// import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
// import * as auth from '../redux/AuthRedux'
// import { login } from '../redux/AuthCRUD'
// import { toAbsoluteUrl } from '../../../../static/helpers'
import { useHistory } from "react-router-dom";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),

  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  let history = useHistory();
  // const [tenant, set_Tanent] = useState(false)
  const [loading, setLoading] = useState(false)




  // const dispatch = useDispatch()
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: loginSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     setLoading(true);
  //     var obj = JSON.parse(localStorage.getItem("login") || '{}')
  //     console.log(obj.login)
  //     var data = { email: values.email, paswd: values.password }
  //     fetch(BASE_API_URL   + '/multitenant_login/', {
  //       method: "POST",
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(data)
  //     }).then((response) => {
  //       response.json()
  //         .then((result) => {
  //           // console.warn(result)
  //           setLoading(false)
  //           setStatus(result.detail)
  //           setSubmitting(false)
  //           console.log("result",result)
  //           if (result.access) {
  //             localStorage.setItem("token", JSON.stringify({
  //               access: result.access,
  //               refresh: result.Refresh,
  //             }))
            
  //             localStorage.setItem("login", JSON.stringify({
  //               login: true,
  //               userdetail: result.payload[0],
  //             }))
  //             history.push("/");
  //             window.location.reload()
  //           }
  //         })
  //     });
  //   },
  // })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      
      try {
        // Retrieve any previously stored login information
        const storedLogin = JSON.parse(localStorage.getItem("login") || '{}');
        console.log(storedLogin.login);
        // Prepare data for the request
        const data = { email: values.email, paswd: values.password };
        // Send the login request
        const response = await fetch(BASE_API_URL + '/multitenant_login/', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Parse the response JSON
        const result = await response.json();
        // Handle successful login
        setStatus(result.detail);
        setSubmitting(false);
        console.log("result", result);
        if (result.access) {
          // Store tokens and user details in localStorage
          localStorage.setItem("token", JSON.stringify({
            access: result.access,
            refresh: result.Refresh,
          }));
          localStorage.setItem("login", JSON.stringify({
            login: true,
            userdetail: result.payload[0],
          }));
  
          // Redirect to the homepage
          history.push("/");
          window.location.reload();
        }
  
      } catch (error) {
        // Handle errors (network issues, server errors, etc.)
        console.error("Failed to log in:", error);
        setStatus("Login failed. Please try again.");
        setSubmitting(false);
      } finally {
        // Always stop loading, regardless of success or failure
        setLoading(false);
      }
    },
  });
  

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'  >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to WORKELEVATE</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
          Please use your organization-issued login details to move forward.
          </div>
        </div>
      )}

      {/* <div className="form-check fv-row mb-5">
      <label className="form-label fs-6 fw-bolder text-dark" htmlFor="flexCheckDefault">
            Is Tanent
          </label>
        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => set_Tanent( e.target.checked)} />
      </div> */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input placeholder='Email'
         {...formik.getFieldProps('email')}
          className={clsx('form-control form-control-lg form-control-solid',
          { 'is-invalid': formik.touched.email && formik.errors.email }, 
          { 'is-valid': formik.touched.email && !formik.errors.email, }
        )} type='email' name='email' autoComplete='off' />
        

        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Link */}
        {/* <Link
          to='/auth/forgot-password'
          className='link-primary fs-6 fw-bolder'
          style={{ marginLeft: '5px' }}
        >
          Forgot Password ?
        </Link> */}
        {/* end::Link */}

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
