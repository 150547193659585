/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { KTSVG } from '../../../helpers'
import { Chart_table } from './Chart_table'


type Props = {
  className: string;
  intents: any[];
  intentscount: any[];
  preintentcont: any[];
  loader: boolean;
}

const Top_10_intents: React.FC<Props> = ({ className, intents, intentscount, preintentcont, loader }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [intnts, setintnts] = useState<string[]>([])
  const [intentcnt, setintentcnt] = useState<string[]>([])
  const [pre_intentcnt, setPre_intentcnt] = useState<string[]>([])

  const top7intnts = intnts.slice(0, 7)
  const top7intentcnt = intentcnt.slice(0, 7)
  const top7pre_intentcnt = pre_intentcnt.slice(0, 7)

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setintnts(intents)
    setintentcnt(intentscount)
    setPre_intentcnt(preintentcont)
    setLoading(loader)

    if (!chartRef.current) {
      return;
    }
    const height = parseInt(getCSS(chartRef.current, 'height'));
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, top7intnts, top7intentcnt, top7pre_intentcnt));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  },);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      {loading && <div className="chart-loader d-flex flex-column-reverse">
        <div className='d-flex row-reverse m-0 justify-content-between align-items-end px-3'>
          <div className='custom-card-icon w-25px h-25px'></div>
          <div className='d-flex flex-column w-75 align-items-end'>
            <div className="custom-card-header w-25"></div>
            <div className="custom-card-header"></div>
          </div>
        </div>
        <div className='d-flex row m-0 justify-content-between h-100'>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
        </div>
      </div>}
      <div className='card-header border-0 pt-3 m-0'>
        <h3 className='card-title align-items-start flex-column w-100'>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className='card-label fw-bolder fs-3 mb-1'>Top 10 intents
            </span>
            <button className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-bs-toggle="dropdown">
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-left shadow" x-placement="top-left">
              <div className="menu-item px-3">
                <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                </div>
              </div>

              <div className="menu-item px-3">
                <a href="#" className="menu-link px-2 text-gray-700 bg-hover-light-primary rounded text-hover-primary" data-bs-toggle="modal" data-bs-target="#viewTable3">
                  <i className="bi-eye fs-5 me-3 text-gray-700"></i> View Data
                </a>
              </div>
              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link px-2 text-gray-700 bg-hover-light-success rounded text-hover-success"
                  // onClick={() => downloadchart()}
                  download="chart.png"
                >
                  <i className="bi-download fs-5 me-3 text-gray-700"></i>Export Chart
                </a>
              </div>
            </div>
          </div>
          <span className='text-muted fw-bold fs-7'>Intents</span>
        </h3>
        {/* begin::Toolbar */}
        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >
            Year
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_week_btn'
          >
            Week
          </a>
        </div> */}
        {/* end::Toolbar */}
      </div>
      
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-5'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '400px' }}></div>
        {/* end::Chart */}
      </div>
      <div className="modal fade" id="viewTable3" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-full" role="document">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Number of top Slots</h5>
              <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <Chart_table className={className} chartDataname={intents} dataValue={intentscount} preDataValue={pre_intentcnt} loader={loader}
            />
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}
export { Top_10_intents }

function getChartOptions(height: number, intnts: any, intentcnt: any, pre_intentcnt: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Current',
        data: intentcnt,
      },
      {
        name: 'From last same days',
        data: pre_intentcnt,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: intnts,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' Times'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
