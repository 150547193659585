
import React, { FC, useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
// import {shallowEqual, useSelector} from 'react-redux'
import { MasterLayout } from '../../static/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { useHistory } from "react-router-dom";
import { Refreshtocken } from './RefreshToken'
import { Backbuttonlink } from './backbuttonlink'
// import {RootState} from '../../setup/redux/RootReducer'
const BASE_API_URL = process.env.REACT_APP_API_URL;

const Routes: FC = () => {
  let history = useHistory();
  const [isAuthorized, setisAuthorized] = useState(false)
  const [isIframe, setIsIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let login = localStorage.getItem("token")
    // console.warn(login)
    if (!login) {
      setisAuthorized(true)
    }
    setIsLoading(false); // Set loading to false once the check is done
  }, [])

  useEffect(() => {
    let login = localStorage.getItem("token")
    // Check if the current window is the topmost window
    if (window.self !== window.top) {
      setIsIframe(true);
      if (!login) {
        const parentURL = Array.from(window.location.ancestorOrigins);
        console.log("parentURL", parentURL)
        const urlParams = new URLSearchParams(window.location.search);
        const encrypted_data = urlParams.get('encrypted_data');

        var data = { encrypted_data: encrypted_data, parentURL: parentURL }
        fetch(BASE_API_URL + '/multitenant_login_With_Iframe/', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then((response) => {
          response.json()
            .then((result) => {
              console.log("result", result)
              if (result.access) {
                localStorage.setItem("token", JSON.stringify({
                  access: result.access,
                  refresh: result.Refresh,
                }))

                localStorage.setItem("login", JSON.stringify({
                  login: true,
                  userdetail: result.payload[0],
                }))
                setisAuthorized(true);
                history.push("/");
                window.location.reload()
              }
            })
        });

      } else {
        console.log("Not running inside an iframe");
      }
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while checking authorization
  }

  return (
    <Switch>
      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <div>
          {isIframe ? (
            <Refreshtocken>
              <Backbuttonlink />
              <PrivateRoutes />
            </Refreshtocken>
          ) : (
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          )}
        </div>

      )}



    </Switch>
  )
}

export { Routes }
