/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuComponent } from '../../../assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'

export function HeaderWrapper() {
  const { pathname } = useLocation()
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config

  const BASE_API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  const [count, setCount] = useState(60); // Initial countdown value
  const [timer, settimer] = useState(0); // Initial timerdown value
  const logouttime = 300;

  useEffect(() => {
    const intervalId = setInterval(() => {
      settimer(prevTimer => prevTimer + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    if (timer > logouttime) {
      setCount(prevCount => prevCount - 1);
    }
    if (timer >= 290) { // 5 seconds of inactivity
      if (timersetting < 15){
        cuntinue_logedin()
      }
    }
  
    if (count < 1) {
      logout()
    }
  }, [timer]);


  function logout() {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem('selected_tenant')
    localStorage.clear()
    window.location.reload();
  }

  const [timersetting, setTimersetting] = useState(0);
  const [isActive, setIsActive] = useState(true);
  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    const resetTimersetting = () => {
      setTimersetting(0);
      clearInterval(intervalId);
    };

    const startTimersetting = () => {
      intervalId = setInterval(() => {
        setTimersetting(prevTimersetting => prevTimersetting + 1);
      }, 1000);
    };

    const handleUserActivity = () => {
      setIsActive(false)
      if (!isActive) {
        setIsActive(true);
        resetTimersetting(); // Reset the timersetting when activity is detected
      }
    };

    startTimersetting();
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [isActive]);

  useEffect(() => {
    if (timersetting > logouttime && isActive) { // 5 seconds of inactivity
      console.log('Logout due to inactivity');
      setIsActive(false); // Reset isActive to prevent multiple logout calls
      // setCount(prevCount => prevCount - 1);
    }
  }, [timersetting, isActive]);



  function cuntinue_logedin() {
    var toknobj = JSON.parse(localStorage.getItem("token") || "{}")
    var data = { "refresh": toknobj.refresh }
    console.log(data)
    fetch(BASE_API_URL + '/TokenRefreshView/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.json().then((result) => {
        console.log('result', result);
        if (result.access) {
          toknobj.access = result.access
          localStorage.setItem('token', JSON.stringify(toknobj));
          setTimersetting(0)
          setCount(60)
          settimer(0)
        }

      });
    });
  }

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/we-black-logo - Copy.png')} className='h-30px' />
            </Link>
          </div>
        )}


        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/we-black-logo - Copy.png')} className='h-30px' />
            </Link>
          </div>
        )}

        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}
          <div>
            {/* <div>{timersetting}</div>
            <div>{timer}</div>
            {isActive?"Active":"InActive"} */}

            {timer > logouttime && <>
              <div className="guide-backdrop"></div>
              <li className="nav-item  me-0 my-2 guide-id" >
                <div className='session_logout'>
                  <div className=' px-2 py-2 rounded mt-2 text-dark  '>Your Session will expire in <b>{count}</b> second, Do you want to continue logged-in. </div>
                  {/* <div className='d-flex justify-content-end align-items-center mt-3'>
                    <button className='btn btn-sm btn-dark ms-3' onClick={(e) => logout()}>Logout</button>
                    <button className='btn btn-sm btn-primary ms-3' onClick={(e) => cuntinue_logedin()}>Continue</button>
                  </div> */}

                </div>
              </li>
            </>}

          </div>
          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
