import React, {useEffect} from "react";
import { PrivateRoutes } from '../../routing/PrivateRoutes'
import { MasterLayout } from '../../../static/layout/MasterLayout'
import { useHistory } from "react-router-dom";

function Dashboardlayout(){
    let history = useHistory();

    useEffect(() => {
        let login = localStorage.getItem("login")
        if (!login) {
            history.push("/auth/login");
        }
    })

    return(
        <MasterLayout >
            <PrivateRoutes />
        </MasterLayout>   
    )   
}
export default Dashboardlayout;