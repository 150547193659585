import React, { FC, useState, useEffect } from 'react';
import { widgetHeight } from '../../../../lib/codemirror5-master/src/measurement/widgets';

const Toggle: FC = () => {
  const [isSuperUser, setIsSuperUser] = useState(false);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("login") || '{}');
    if (obj.userdetail !== undefined) {
      // console.log(obj.userdetail.is_superuser);
      setIsSuperUser(obj.userdetail.is_superuser);
    }
  }, []);

  return (
    <div className='rightSticyBtn' style={{ position: 'fixed', right: '0', zIndex: 11, display: 'flex', flexDirection: 'column', top: '43%', width: '30px' }}>


      {isSuperUser && (
        <>
          <button style={{ width: '124px', position: 'absolute' }}
            id='kt_explore_toggle'
            className='exploreBtn btn btn-sm btn-white btn-active-primary shadow-sm px-5 fw-bolder top-50 mt-20 end-0 transform-90 fs-6 rounded-top-0'
            title={`Explore ${process.env.REACT_APP_THEME_NAME}`}
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-trigger='hover'
          >
            <span id='kt_explore_toggle_label'>Explore</span>
          </button>
          <button
            id='kt_SuperUserOnly_toggle'
            style={{ marginTop: '18rem', position: 'absolute', width: '159px' }}
            className='superUserBtn btn btn-sm btn-white btn-active-primary shadow-sm px-5 fw-bolder top-50 end-0 transform-90 fs-6 rounded-top-0'
          >
            <span id='kt_SuperUserOnly_toggle_label'>Super User Only</span>
          </button>
        </>

      )}
    </div>
  );
};

export { Toggle };
