import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../static/partials';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { useHistory } from 'react-router-dom';
// import { windows } from '../../lib/codemirror5-master/src/util/browser';
const BASE_API_URL = process.env.REACT_APP_API_URL;

interface PermissionWrapperProps {
  requiredPermission: any;
  children: React.ReactNode;
}
let webdomain = process.env.REACT_APP_API_URL;

function PermissionWrapper({ children }: PermissionWrapperProps) {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const history = useHistory();
  // const [rolepermissions, setrolePermissions] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const permission = await checkUserPermission();
        console.log("permissionpermission", permission)
        setHasPermission(permission);
      } catch (error) {
        setHasPermission(false);
      }
    };
    fetchData();
  }, []);



  if (hasPermission === null) {
    return <div>Loading...</div>;
  }
  if (hasPermission) {
    return <div>{children}</div>;
  } else {
    return (
      <div className='text-center p-5' style={{ height: "83vh" }}>
        <div>You do not have access to this page!</div>
        <button className='btn btn-primary' onClick={() => history.push("/")}>
          Home
        </button>
      </div>
    );
  }
}








async function checkUserPermission(): Promise<boolean> {
  try {
    const loginData = localStorage.getItem("login");
    const tokenData = localStorage.getItem("token");

    if (!loginData || !tokenData) {
      console.error("Login or token data is missing");
      return false;
    }

    const obj = JSON.parse(loginData);
    const tokn = JSON.parse(tokenData);
    const access_token = tokn.access;

    if (!access_token) {
      console.error("Access token is missing");
      return false;
    }

    const userDetail = obj.userdetail || {};
    const roles = userDetail.roles || ["None"];
    const data = {
      domain: userDetail.subdomain,
      usertype: userDetail.userType,
      role: roles,
      user_id: userDetail.id,
    };

    const response = await fetch(BASE_API_URL + "/MultiTenant_Get_Menu_two/", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error("Failed to fetch permissions", response.statusText);
      return false;
    }

    const json = await response.json();
    console.log("JSON", json);

    const permissionsObject = json.reduce((acc: Record<string, string>, element: any) => {
      acc[element.link.replace(" ", '%20')] = element.permisstion;
      return acc;
    }, {});

    const currentURL = window.location.href;
    const lastPart = "/" + currentURL.split("/").pop()?.replace(/ /g, '%20') || "";

    if (userDetail.is_superuser) {
      return true;
    }
 
    if (userDetail.userType === "Tenant") {
      for (const submenus of json) {
        const submenusArray = eval(submenus["submenu"]);
        for (const submenu of submenusArray) {
          if (submenu.link === lastPart) {
            return true;
          }
          else{
            return false;
          }
        }
      }
      console.log(`${lastPart} is present in the JSON object.`);
      return true;
    }

    const data2 = { domain: userDetail.subdomain, role: userDetail.roles.join() || "None" };
    const response2 = await fetch(BASE_API_URL + "/MultiTenant_Get_Role_Permissions/", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
      },
      body: JSON.stringify(data2),
    });

    if (!response2.ok) {
      console.error("Failed to fetch permissions", response2.statusText);
      return false;
    }

    
    const json2 = await response2.json();
    console.log("JSON2222", json2);

   

    for (const submenus of json) {
      const submenusArray = eval(submenus["submenu"]);
      for (const submenu of submenusArray) {
        if (submenu.link === lastPart) {
          if (json2[submenu.SubMenu] === "1") {
            return true;
          } else {
            return false;
          }
        }
      }
    }

    // if (permissionsObject[lastPart] === "1") {
    //   return true;
    // } else {
    //   console.log(`${lastPart} is NOT present in the JSON object.`);
    //   return false;
    // }
    console.log(`${lastPart} is NOT present in the JSON object.`);
    return false;

  } catch (error) {
    console.error("Error fetching user permissions", error);
    return false;
  }
}


// async function checkUserPermission(): Promise<boolean> {

//   try {
//     const obj = JSON.parse(localStorage.getItem("login") || '{}');
//     var tokn = JSON.parse(localStorage.getItem("token") || '{}')
//     var access_token = tokn.access
//     let data = {};
//     if (obj.userdetail.roles === undefined) {
//       data = { domain: obj.userdetail.subdomain, usertype: obj.userdetail.userType, role: ["None"], user_id: obj.userdetail.id };
//     } else {
//       data = { domain: obj.userdetail.subdomain, usertype: obj.userdetail.userType, role: obj.userdetail.roles, user_id: obj.userdetail.id };
//     }
//     const response = await fetch(BASE_API_URL + "/MultiTenant_Get_Menu_two/", {
//       method: "POST",
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + access_token
//       },
//       body: JSON.stringify(data),
//     });
//     const json = await response.json();
//     const permissionsObject = json.reduce((acc: any, element: any) => {
//       acc[element.link] = element.permission;
//       return acc;
//     }, {} as Record<string, string>);

//     const currentURL = window.location.href;
//     const parts = currentURL.split("/");
//     const lastPart = parts[parts.length - 1].toLowerCase();

//     const updatedPermissionsObject: { [key: string]: string } = {};
//     console.log("currentURL", currentURL)
//     Object.keys(permissionsObject).forEach((key) => {
//       const updatedKey = key.replace(/ /g, '%20').toLowerCase();
//       updatedPermissionsObject[updatedKey] = permissionsObject[key];
//     });

//     if (obj.userdetail.is_superuser) {
//       return true;
//     }

//     if (obj.userdetail.userType === "Tenant") {
//       if (updatedPermissionsObject.hasOwnProperty(lastPart)) {
//         console.log(`${lastPart} is present in the JSON object.`);
//         return true;
//       } else {
//         console.log(`${lastPart} is NOT present in the JSON object.`);
//       }
//     }

//     const per = updatedPermissionsObject[lastPart];
//     if (per === "1") {
//       return true;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     console.error("Error fetching user permissions", error);
//     return false;
//   }
// }

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
  const Campaign = lazy(() => import('../pages/campaign/capaignWrapper'));
  const GoogleChat = lazy(() => import('../pages/google-chat/capaignWrapper'))
  const BotBoilder = lazy(() => import('../pages/botbuilder/botbuilderWrapper'));
  const QAModel = lazy(() => import('../pages/QAModel/botbuilderWrapper'))
  const Integration = lazy(() => import('../pages/integration/botbuilderWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const Fileuploader = lazy(() => import('../modules/fileuploader/FileUploader'))
  const Dumpdata = lazy(() => import('../modules/dumpdata/Dumpdata'))
  const Maketenant = lazy(() => import('../modules/MakeTenant/MakeTenant'))
  const PendingCard = lazy(() => import('../modules/Pending_Cards/Pending_Cards'))
  const Locel = lazy(() => import('../modules/profile copy/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const Telemetry = lazy(() => import('../modules/telemetry/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const ChatHistory = lazy(() => import('../modules/apps/chathistory/ChatPagehistory'))

  const [landingpage, setlandingpage] = useState('')
  useEffect(() => {
    const loginData = localStorage.getItem("login");
    if (loginData) {
      try {
        const obj = JSON.parse(loginData);
        if (obj.userdetail.userType === "Tenant") {
          setlandingpage("/Configuration")
        }
        else if (obj.userdetail && obj.userdetail.roles) {
          var data = { domain: obj.userdetail.subdomain, role: obj.userdetail.roles }
          fetch(BASE_API_URL + "/get_landing_link/",
            {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(result => {
              setlandingpage(result[0]["landing_page"])
              console.log("result", result[0]["landing_page"])
            })
        } else {
          console.warn("userdetail or Langding_url is not available in the login data");
        }
      } catch (error) {
        console.error("Failed to parse login data from localStorage", error);
      }
    } else {
      console.warn("No login data found in localStorage");
    }
  }, []);



  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard'>
          <PermissionWrapper requiredPermission={true}>
            <DashboardWrapper />
          </PermissionWrapper>
        </Route>
        <Route path='/Configuration'>
          <PermissionWrapper requiredPermission={true}>
            <BuilderPageWrapper />
          </PermissionWrapper>
        </Route>
        <Route path='/msteams'>
          <PermissionWrapper requiredPermission={true}>
            <Campaign />
          </PermissionWrapper>
        </Route>
        <Route path='/googlechat'>
          <PermissionWrapper requiredPermission={true}>
            <GoogleChat />
          </PermissionWrapper>
        </Route>
        <Route path='/botbuilder'>
          <PermissionWrapper requiredPermission={true}>
            <BotBoilder />
          </PermissionWrapper>
        </Route>
        <Route path='/qamodel'>
          <PermissionWrapper requiredPermission={true}>
            <QAModel />
          </PermissionWrapper>
        </Route>
        <Route path='/integration'>
          <PermissionWrapper requiredPermission={true}>
            <Integration />
          </PermissionWrapper>
        </Route>
        <Route path='/telemetry'>
          <PermissionWrapper requiredPermission={true}>
            <Telemetry />
          </PermissionWrapper>
        </Route>
        <Route path='/crafted/pages/profile'>
          <PermissionWrapper requiredPermission={true}>
            <ProfilePage />
          </PermissionWrapper>
        </Route>
        <Route path='/locel'>
          <PermissionWrapper requiredPermission={true}>
            <Locel />
          </PermissionWrapper>
        </Route>
        <Route path='/dumpdata'>
          <PermissionWrapper requiredPermission={true}>
            <Dumpdata />
          </PermissionWrapper>
        </Route>
        <Route path='/maketenant'>
          <PermissionWrapper requiredPermission={true}>
            <Maketenant />
          </PermissionWrapper>
        </Route>
        <Route path='/pendingcard'>
          {/* <PermissionWrapper requiredPermission={false}> */}
            <PendingCard />
          {/* </PermissionWrapper> */}
        </Route>
        <Route path='/crafted/pages/wizards'>
          <PermissionWrapper requiredPermission={true}>
            <WizardsPage />
          </PermissionWrapper>
        </Route>
        <Route path='/crafted/widgets'>
          <PermissionWrapper requiredPermission={true}>
            <WidgetsPage />
          </PermissionWrapper>
        </Route>
        <Route path='/crafted/account'>
          <PermissionWrapper requiredPermission={true}>
            <AccountPage />
          </PermissionWrapper>
        </Route>
        <Route path='/apps/chat'>
          <PermissionWrapper requiredPermission={true}>
            <ChatPage />
          </PermissionWrapper>
        </Route>
        <Route path='/chathistory'>
          <PermissionWrapper requiredPermission={true}>
            <ChatHistory />
          </PermissionWrapper>
        </Route>
        <Route path='/menu-test'>
          <PermissionWrapper requiredPermission={true}>
            <MenuTestPage />
          </PermissionWrapper>
        </Route>
        <Redirect from='/auth' to={landingpage} />
        <Redirect exact from='/' to={landingpage} />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  );
}
