import React, { useEffect, useState, ReactNode } from 'react';

interface RefreshtockenProps {
  children: ReactNode;
}

export function Refreshtocken({ children }: RefreshtockenProps) {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [count, setCount] = useState(60); // Initial countdown value
  const [timer, settimer] = useState(0); // Initial timer value
  const logouttime = 900;

  useEffect(() => {
    const intervalId = setInterval(() => {
      settimer(prevTimer => prevTimer + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timer > logouttime) {
      setCount(prevCount => prevCount - 1);
    }
    if (timer >= 290) { // 5 seconds of inactivity
      if (timersetting < 15){
        continue_logged_in();
      }
    }
  
    if (count < 1) {
      logout();
    }
  }, [timer]);

  function logout() {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem('selected_tenant');
    localStorage.clear()
    window.location.reload();
  }

  const [timersetting, setTimersetting] = useState(0);
  const [isActive, setIsActive] = useState(true);
  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    const resetTimersetting = () => {
      setTimersetting(0);
      clearInterval(intervalId);
    };

    const startTimersetting = () => {
      intervalId = setInterval(() => {
        setTimersetting(prevTimersetting => prevTimersetting + 1);
      }, 1000);
    };

    const handleUserActivity = () => {
      setIsActive(false);
      if (!isActive) {
        setIsActive(true);
        resetTimersetting(); // Reset the timersetting when activity is detected
      }
    };

    startTimersetting();
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [isActive]);

  useEffect(() => {
    if (timersetting > logouttime && isActive) { // 5 seconds of inactivity
      console.log('Logout due to inactivity');
      setIsActive(false); // Reset isActive to prevent multiple logout calls
      // setCount(prevCount => prevCount - 1);
    }
  }, [timersetting, isActive]);

  function continue_logged_in() {
    var toknobj = JSON.parse(localStorage.getItem("token") || "{}");
    var data = { "refresh": toknobj.refresh };
    console.log(data);
    fetch(BASE_API_URL + '/TokenRefreshView/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.json().then((result) => {
        console.log('result', result);
        if (result.access) {
          toknobj.access = result.access;
          localStorage.setItem('token', JSON.stringify(toknobj));
          setTimersetting(0);
          setCount(60);
          settimer(0);
        }
      });
    });
  }

  return (
    <div>
      {children}
      <div className=''>
        <div className='position-absolute top-25 start-25'>
          {timer > logouttime && <>
            <div className="guide-backdrop"></div>
            <li className="nav-item me-0 my-2 guide-id">
              <div className='session_logout'>
                <div className='px-2 py-2 rounded mt-2 text-dark'>
                  Your Session will expire in <b>{count}</b> second, Do you want to continue logged-in.
                </div>
                <div className='d-flex justify-content-end align-items-center mt-3'>
                  <button className='btn btn-sm btn-dark ms-3' onClick={logout}>Logout</button>
                  <button className='btn btn-sm btn-primary ms-3' onClick={continue_logged_in}>Continue</button>
                </div>
              </div>
            </li>
          </>}
        </div>
      </div>
    </div>
  );
}
