import React, {useState,  useEffect } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../helpers'
import { useLayout } from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const BASE_API_URL = process.env.REACT_APP_API_URL;

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  const [permissions, setPermissions] = useState<Record<string, string>>({});
function get_role_permission(){
  var obj = JSON.parse(localStorage.getItem("login") || '{}')
  const userDetail = obj.userdetail || {};
  var data = { domain: userDetail.subdomain, role: userDetail.roles.join() || ["None"],}
  console.log("AAAAAAAAAAA", data)
  fetch(BASE_API_URL + "/MultiTenant_Get_Role_Permissions/",
    {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(json => {
      setPermissions(json)
      console.log("json", json)
    })
}
useEffect(() => {
  var obj = JSON.parse(localStorage.getItem("login") || '{}')
  const userDetail = obj.userdetail || {};
  if (userDetail.roles){
    get_role_permission()
  }
}, [])

if (permissions[title] === "0") {
  return null; // Don't render the component
}

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {/* {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )} */}

        <span className="menu-bullet me-0">
          <i className="bi-arrow-right me-2 fs-3"></i>
        </span>

        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        
      </Link>
        
      {children}
    </div>
  )
}

export { AsideMenuItem }
