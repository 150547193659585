/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
// import { useHistory } from "react-router-dom";
const BASE_API_URL = process.env.REACT_APP_API_URL;

const AsideDefault: FC = () => {
  // let history = useHistory();
  const { config, classes } = useLayout()
  const { aside } = config
  // const [rebranded, setrebranded] = useState(false)
  // const [brand, setbrandname] = useState<any>()
  // const [outsidelogo, setoutsidelogo] = useState<any>()
  const [insidelogo, setinsidelogo] = useState<any>()
  // const [titlelogo, settitlelogo] = useState<any>()



  // let webdomain = "http://127.0.0.1:8000"
 

  useEffect(() => {
    var obj = JSON.parse(localStorage.getItem("login") || '{}')
    if (obj.userdetail) {
      findbrand()
    }
  }
    , [])

  function findbrand() {
    var obj = JSON.parse(localStorage.getItem("login") || '{}')
    var data = { "subdomain": obj.userdetail.subdomain }
    console.log("findbrand", data)
    fetch(BASE_API_URL + '/MultiTenant_get_rebranding/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.json()
        .then((result) => {
          console.log("rebrand", result)
          localStorage.setItem("rebrand", JSON.stringify({
            rebanded: true,
            outsidelogo: result[0].outsidelogo,
            insidelogo: result[0].insidelogo,
            titlelogo: result[0].titlelogo,
          }))
          setband()
        })
        .catch(error => {
          console.warn(error)
        });
    });
  }

  
  function setband() {
    var obj = JSON.parse(localStorage.getItem("rebrand") || '{}')
    setinsidelogo(obj.insidelogo)
  }

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
           
              <>
                <img
                  alt='Logo'
                  className='logo'
                  style={{height:"18px"}}
                  // src ="http://127.0.0.1:8000/media/image/inside/we-black-logo.png"
                  // src={endpoint + insidelogo}
                  src ={toAbsoluteUrl('/media/logos/logo-1-dark.png')}
                />
                <img
                  alt='Logo'
                  className='min-logo'
                  // src ="http://127.0.0.1:8000/media/image/inside/we-black-logo.png"
                  // src={endpoint + insidelogo}
                  src ={toAbsoluteUrl('/media/logos/fav-icon.png')}
                />
              </>

              
              {/* <img
                alt='Logo'
                className='h-25px logo'
                src={webdomain + "/media/image/inside/we-black-logo.png"}
              // src={endpoint + insidelogo}
              /> */}
            
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-25px logo'
              src={toAbsoluteUrl('/media/logos/logo-1.svg')}
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle active mr-m-22 toggle-btn'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr079.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        <a
          target='_blank'
          className='btn btn-custom btn-primary w-100'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL}
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          title='Check out the complete documentation with over 100 components'
        >
          <span className='btn-label'>Docs & Components </span>
          <span className='svg-icon btn-icon svg-icon-2'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' />
          </span>
        </a>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export { AsideDefault }
