/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Toggle } from './Toggle'
import { Demos } from './Demos'
import { KTSVG } from '../../../helpers'
import { auto } from '@popperjs/core'
import { useHistory } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'

export function ExploreMain() {
  const history = useHistory()

  return (
    <>
      <Toggle />
      {/* begin::Exolore drawer */}
      <div
        id='kt_explore'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='explore'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_explore_toggle'
        data-kt-drawer-close='#kt_explore_close'
      >
        {/* begin::Card  */}
        <div className='card shadow-none w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_explore_header'>
            <h5 className='card-title fw-bold text-gray-600'>
              Explore {process.env.REACT_APP_THEME_NAME}
            </h5>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_explore_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body' id='kt_explore_body'>
            {/* begin::Content */}
            <div
              id='kt_explore_scroll'
              className='scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_explore_body'
              data-kt-scroll-dependencies='#kt_explore_header, #kt_explore_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='mb-7'>
                <div className='d-flex flex-stack'>
                  <h3 className='mb-0'>{process.env.REACT_APP_THEME_NAME} Licenses</h3>

                  <a
                    href='https://themeforest.net/licenses/standard'
                    className='fw-bold'
                  >
                    License FAQs
                  </a>
                </div>
              </div>

              <div className='rounded border border-dashed border-gray-300 py-4 px-6 mb-5'>
                <div className='d-flex flex-stack'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <div className='fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1'>
                        Regular License
                      </div>

                      <i
                        className='text-gray-400 fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='popover'
                        data-bs-custom-classname='popover-dark'
                        data-bs-trigger='hover'
                        data-bs-placement='top'
                        data-bs-content='Use, by you or one client in a single end product which end users are not charged for.'
                      ></i>
                    </div>
                    <div className='fs-7 text-muted'>
                      For single end product used by you or one client
                    </div>
                  </div>

                  <div className='text-nowrap'>
                    <span className='text-muted fs-7 fw-bold'>$</span>
                    <span className='text-dark fs-1 fw-bolder'>39</span>
                  </div>
                </div>
              </div>

              <div className='rounded border border-dashed border-gray-300 py-4 px-6 mb-5'>
                <div className='d-flex flex-stack'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <div className='fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1'>
                        Extended License
                      </div>

                      <i
                        className='text-gray-400 fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='popover'
                        data-bs-custom-classname='popover-dark'
                        data-bs-trigger='hover'
                        data-bs-placement='top'
                        data-bs-content='Use, by you or one client, in a single end product which end users can be charged for.'
                      ></i>
                    </div>
                    <div className='fs-7 text-muted'>For single end product with paying users.</div>
                  </div>

                  <div className='text-nowrap'>
                    <span className='text-muted fs-7 fw-bold'>$</span>
                    <span className='text-dark fs-1 fw-bolder'>939</span>
                  </div>
                </div>
              </div>

              <div className='rounded border border-dashed border-gray-300 py-4 px-6 mb-5'>
                <div className='d-flex flex-stack'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <div className='fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1'>
                        Custom License
                      </div>
                    </div>
                    <div className='fs-7 text-muted'>Reach us for custom license offers.</div>
                  </div>

                  <div className='text-nowrap'>
                    <a
                      href='https://WORKELEVATE.com/contact/'
                      className='btn btn-sm btn-success'
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>

              <a href={process.env.REACT_APP_PURCHASE_URL} className='btn btn-primary mb-15 w-100'>
                Buy Now
              </a>
              <Demos />
            </div>
            {/* end::Content */}
          </div>
          {/* end::Body */}
        </div>
        {/* begin::Card */}
      </div>
      <div
        id='kt_SuperUserOnly'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='SuperUserOnly'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_SuperUserOnly_toggle'
        data-kt-drawer-close='#kt_SuperUserOnly_close'
      >
        {/* begin::Card  */}
        <div className='card shadow-none w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_SuperUserOnly_header'>
            <h5 className='card-title  text-gray-600'>
              Super User Only {process.env.REACT_APP_THEME_NAME}
            </h5>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon SuperUserOnly-btn-dismiss me-n5'
                id='kt_SuperUserOnly_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body' id='kt_SuperUserOnly_body'>
            {/* begin::Content */}
            <div
              id='kt_SuperUserOnly_scroll'
              className='scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_SuperUserOnly_body'
              data-kt-scroll-dependencies='#kt_SuperUserOnly_header, #kt_SuperUserOnly_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='row'>
                {/* <div className='btn col-sm-6 d-flex py-1'>
                  
                </div > */}
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/dashboard")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                      < KTSVG path='/media/svg/misc/dashboard-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' />
                    </div>
                    <div className='ms-5' >
                      <a className='fs-8 fw-bolder text-dark mb-2' > Dashboard</a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' >Sales Statistics, Activities, Generate Reports</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/msteams")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                      < KTSVG path='/media/svg/brand-logos/microsoft-teams-logo-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' />
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}>MS Teams </a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>This is under the campaign Management</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/googlechat")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                      {/* < KTSVG path='/media/svg/misc/bars-square-s-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' /> */}
                        <i className="bi-google text-primary fs-1"></i>
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}> Google Chat</a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>This is under the campaign Management</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/Configuration")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px symbol-circle' style={{ fontSize: "14px" }}>
                      < KTSVG path='/media/svg/misc/chip-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary' />
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}> Configuration</a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>User Management, Access Management, Password Mechanism</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/telemetry")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                      < KTSVG path='/media/svg/misc/dot-grid-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' />
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}> Telimetry </a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>WORKELEVATE Bot Users, Conversations sessions & Average, User Query</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/chathistory/private-chat")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                      < KTSVG path='/media/svg/misc/history-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' />
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}> Chat History </a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>Sales Statistics, Activities, Generate Reports</div>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/botbuilder")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                    <i className="bi-chat-right-dots text-primary fs-1"></i>
                      {/* < KTSVG path='/media/svg/misc/history-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' /> */}
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}> Bot Builder </a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>Sales Statistics, Activities, Generate Reports</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/qamodel")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                    <i className="bi-arrow-left-right text-primary fs-1"></i>
                      {/* < KTSVG path='/media/svg/misc/history-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' /> */}
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}> Q & A Model </a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>Sales Statistics, Activities, Generate Reports</div>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/locel")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px symbol-circle'>
                    <i className="bi-globe2 text-primary fs-1"></i>
                      {/* < KTSVG path='/media/svg/misc/history-svgrepo-com.svg'
                        className='svg-icon-1 svg-icon-primary ' /> */}
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}>Manage Locel</a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>Set Language, Set Locel, Set TimeZone</div>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/dumpdata")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px '>
                    <i className="bi-server text-primary fs-1"></i>
                    
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}>Dump Data</a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>Select a class, submit your choice, and dump the data in JSON format.</div>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 d-flex flex-stack py-1 mb-5' style={{ cursor: "pointer" }} onClick={() => history.push("/maketenant")}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px '>
                    <i className="bi-building text-primary fs-1"></i>
                    
                    </div>
                    <div className='ms-5'>
                      <a className='fs-8 fw-bolder mb-2' style={{ color: "#454747" }}>Make Tenant</a>
                      <div className='fw-bold fs-10 text-gray-700 text-wrap' style={{ fontSize: "9px" }}>this endpoint  facilitates the registration of new tenant users in a multi-tenant application.</div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
            {/* end::Content */}
          </div>
          {/* end::Body */}
        </div>
        {/* begin::Card */}
      </div>
      {/* end::Exolore drawer */}
    </>
  )
}
