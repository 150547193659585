import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks } from '../../../partials'
import { useLayout } from '../../core'
import { Link } from 'react-router-dom'

const BASE_API_URL = process.env.REACT_APP_API_URL;

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'


const Topbar: FC = () => {
  const { config } = useLayout()
  const [is_superuser, setis_superuser] = useState(false)
  const [is_Tenant_Select, setis_Tenant_Select] = useState(false)
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    var obj = JSON.parse(localStorage.getItem("login") || '{}')
    if (obj.userdetail !== undefined) {
      setis_superuser(obj.userdetail.is_superuser)
      setBase64Image(obj.userdetail.profile_pic)
    }

    Get_Pending_request()
  }, [])

  function isTenantSelect(value: any) {
    setis_Tenant_Select(value)
  }
  interface pendingRequest_list_interface {
    Attachment: {},
    CampaignName: string,
    Sendby_name: string,
    Status: string,
    TeamLead_Email: string | null,
    TeamLead_Name: string | null,
    approval_levelID: string,
    card_name: string,
    channel: string,
    domain: string,
    id: string,
    request_id: string,
    selected_user: string,
  }
  const [pendingRequest_list, setpendingRequest_list] = useState<pendingRequest_list_interface[]>([])
  function Get_Pending_request() {
    var obj = JSON.parse(localStorage.getItem("login") || '{}')
    var data1 = { domain: obj.userdetail.subdomain, email: obj.userdetail.email }
    fetch(BASE_API_URL + "/MultiTenant_Get_Pending_request/",
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data1)
      })
      .then((response) => {
        response.json()
          .then((result) => {
            if (!result.message) {
              setpendingRequest_list(result)
            } else {
              setpendingRequest_list([])
            }
            console.log("Pending_request", result)
          })
      });
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      <Link to="/pendingcard" >
        <div className='align-items-center cursor-pointer btn btn-active-light-primary mt-3'>
          <div><i className="bi-bell fs-4"></i></div>
          {pendingRequest_list.length >= 1 &&
            <div className='fs-9 text-white bg-danger rounded bell'> {pendingRequest_list.length}</div>
          }
        </div>
      </Link>

      {/* Quick links */}
      {is_superuser ?
        <>
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <div
              className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
              id='kt_activities_toggle'
            >
              <KTSVG path='/media/icons/duotune/general/gen032.svg' className={toolbarButtonIconSizeClass}
              />
            </div>
          </div>
          <div className={clsx('d-flex align-items-center position-relative', toolbarButtonMarginClass)}>
            {/* begin::Menu wrapper */}
            {is_Tenant_Select && (
              <span className='bullet bullet-dot bg-danger h-9px w-9px position-absolute translate-middle mt-2 ms-6 animation-blink'></span>
            )}
            <div
              className={clsx('btn btn-icon btn-active-light-primary ', toolbarButtonHeightClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >

              <KTSVG
                path='/media/icons/duotune/general/gen025.svg'
                className={toolbarButtonIconSizeClass}
              />

            </div>
            <QuickLinks />
            {/* end::Menu wrapper */}
          </div>
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            {/* begin::Menu- wrapper */}
            <div
              className={clsx(
                'btn btn-icon btn-active-light-primary position-relative',
                toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen022.svg'
                className={toolbarButtonIconSizeClass}
              />
            </div>
            <HeaderNotificationsMenu />
            {/* end::Menu wrapper */}
          </div>
        </>

        :
        ""
      }

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div> */}

      {/* NOTIFICATIONS */}


      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {/* <i class="bi-person-bounding-box"></i> */}
          {/* <i className="bi-person-bounding-box fs-1 "></i> */}
          {base64Image ?
            <img src={base64Image} alt="Preview" className='object-fit-cover' />
            :
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='WORKELEVATE' />
          }
          {/* <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='h-35px w-35px' alt='WORKELEVATE' /> */}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
