import React, { useState, useEffect } from 'react';
import { ChatInner_with_docoment } from '../../../static/partials/chat/ChatInner_with_document';
const TypingEffect = ({ text, speed = 100 }) => {
  const [displayText, setDisplayText] = useState('');
  useEffect(() => {
    
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex === text.length) {
        clearInterval(typingInterval);
      } else {
        setDisplayText(prevText => prevText + text[currentIndex]);
        currentIndex++;
      }
    }, speed);
    
    return () => clearInterval(typingInterval);
  }, [text, speed]);

  return (
    <div>
      {displayText}
    </div>
  );
};

export default TypingEffect;
