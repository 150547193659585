/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { date } from 'yup';
import { Table } from 'react-bootstrap-v5';
// import { KTSVG } from '../../../helpers';
import { toAbsoluteUrl } from '../../../helpers';
import { KTSVG } from '../../../helpers';

type Props = {
  className: string;
  channel: any[];
  loader: boolean;

}
const Number_of_user_by_channel: React.FC<Props> = ({ className, channel, loader }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [channels, setchannels] = useState<string[]>([])
  const [cur_usercount, setcur_usercount] = useState<string[]>([])
  const [lst_usercount, setlst_usercount] = useState<string[]>([])
  const [channelsdata, setchannelsdata] = useState<string[]>([])

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("channel", channel)
    setchannelsdata(channel)
    setLoading(loader)
    if (!chartRef.current) {
      return;
    }
    const height = parseInt(getCSS(chartRef.current, 'height'));
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, channels, cur_usercount, lst_usercount));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  },);

  const specialStyle = {
    color: 'red',
    fontWeight: 'bold',
  };


  return (
    <div className={`card ${className}`}>
      {loading && <div className="chart-loader d-flex flex-column-reverse justify-content-start">
  <div className="d-flex row-reverse justify-content-between px-3 align-items-end">
    <div className="custom-card-icon w-25px h-25px"></div>
    <div className="d-flex flex-column align-items-end w-75">
      <div className="custom-card-header w-25"></div>
      <div className="custom-card-header"></div>
    </div>
  </div>
 
  <div className="d-flex flex-column justify-content-end">
    {[...Array(7)].map((_, index) => (
      <div key={index} className="d-flex row justify-content-between my-3">
        <div className="d-flex justify-content-end align-items-center w-100">
          <div className="custom-card-header w-75"></div>
          <div className="custom-card-icon w-35px h-35px mx-4"></div>
        </div>
      </div>
    ))}
  </div>
</div>}
      {/* begin::Header */}
      <div className='card-header border-0 pt-3 m-0'>
        <h3 className='card-title align-items-start flex-column w-100'>
           <div className="d-flex w-100 justify-content-between align-items-center">
            <span className='card-label fw-bolder fs-3 mb-1'>User count by channel
            </span>
            <button className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-bs-toggle="dropdown">
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-left shadow" x-placement="top-left">
              <div className="menu-item px-3">
                <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                </div>
              </div>
 
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-2 text-gray-700 bg-hover-light-primary rounded text-hover-primary" data-bs-toggle="modal" data-bs-target="#viewTable">
                  <i className="bi-eye fs-5 me-3 text-gray-700"></i> View Data
                </a>
              </div>
              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link px-2 text-gray-700 bg-hover-light-success rounded text-hover-success"
                  // onClick={() => downloadchart()}
                  download="chart.png"
                >
                  <i className="bi-download fs-5 me-3 text-gray-700"></i>Export Chart
                </a>
              </div>
            </div>
          </div>
          <span className='text-muted fw-bold fs-7'> new channels</span>
        </h3>
        {/* begin::Toolbar */}
      </div>
      <div className='card-body p-5 overflow-auto' style={{maxHeight:"410px"}}>
        <table className='table'>
          <tbody>
            {channelsdata.map((items, index) => (
              <tr className=' border-bottom border-secondary' key={index}>
                {console.log("items", Object.values(items)[0][1])}
                <td className='text-gray-800 fw-bold text-hover-primary fs-6'>
                  <div className='d-flex'>
                  {/* src={toAbsoluteUrl(`/media/svg/brand-logos/${index} atica.svg`)} */}
                    <img src={Object.values(items)[0][0][1]}
                      className="me-4 w-30px h-100" style={{ borderRadius: "4px" }} alt="Base64 Image" />
                    <div>
                      <div>{Object.keys(items)}</div>
                      <div className="text-gray-500 fw-semibold fs-7 d-block text-start">Community</div>
                    </div>
                  </div>
                </td>
                <td className='text-gray-800 fw-bold fs-4 me-3'>{Object.values(items)[0][0][0]}</td>
                <td>
                  {Number(Object.values(items)[0][0][0]) > Number(Object.values(items)[0][1]) ?
                    <span className="badge badge-light-success fs-base">
                      <i className="bi bi-chevron-up fs-5 text-success ms-n1 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i >
                      {Object.values(items)[0][1]}
                    </span>
                    :
                    <span className="badge badge-light-danger fs-base">
                      <i className="bi bi-chevron-down fs-5 text-danger ms-n1 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i >
                      {Object.values(items)[0][1]}
                    </span>
                  }

                </td>
              </tr>
            ))}
          </tbody>
        </table>
        

        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >
            Year
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_week_btn'
          >
            Week
          </a>
        </div> */}
        {/* end::Toolbar */}
      </div>
      <div className='btn mt-5'></div>
      {/* end::Header */}

      {/* begin::Body */}
      {/* <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
      </div> */}
      {/* end::Body */}
    </div>
  )
}
export { Number_of_user_by_channel }

function getChartOptions(height: number, channel: any, curusrcount: any, lstusrcnt: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-1000')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')
  const secondaryColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: [
      {
        name: 'Current',
        data: curusrcount,
      },
      {
        name: 'From last same days',
        data: lstusrcnt,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: channel,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' Users'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
