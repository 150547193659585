/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
// import { useIntl } from 'react-intl'
// import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
const BASE_API_URL = process.env.REACT_APP_API_URL;
// import { Link, Route, Router } from 'react-router-dom';
export function AsideMenuMain() {
  // const intl = useIntl()

  const [Menus, SetMenus] = useState([])
  const [Tenant, SetTenant] = useState('')
  const [detail, Setdetail] = useState('')
  // let webdomain = "http://127.0.0.1:8000"

  function getMenus() {
    var sto = localStorage.getItem("login")
    var tokn = JSON.parse(localStorage.getItem("token") || '{}')
    var access_token = tokn.access
    if (sto !== null) {
      var obj = JSON.parse(localStorage.getItem("login") || '{}')
      SetTenant(obj.userdetail.userType)
      console.log(obj.userdetail.subdomain, obj.userdetail.userType, obj.userdetail.roles)
      if (obj.userdetail.roles === undefined) {
        var data1 = { domain: obj.userdetail.subdomain, usertype: obj.userdetail.userType, role: ["None"], user_id: obj.userdetail.id }
        try {
          fetch(BASE_API_URL + "/MultiTenant_Get_Menu_two/", {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + access_token // Replace YOUR_AUTH_TOKEN with the actual token
            },
            body: JSON.stringify(data1)
          })
            .then(response => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then(json => {
              console.log(json);
              if (json.detail) {
                Setdetail(json.code);
              } else {
                SetMenus(json);
                console.log("this is a Menus n", json);
                localStorage.setItem("Menus", JSON.stringify({
                  Menus: json,
                }));
              }
            })
            .catch(error => {
              // Handle errors from fetch or response.json()
              console.error("Failed to fetch data:", error);
              // Optionally, set error state or notify the user
              // For example: SetError("An error occurred while fetching data.");
            });
        } catch (error) {
          // Handle synchronous errors that occur outside fetch
          console.error("Error in fetch block:", error);
        }

      } else {
        var data = { domain: obj.userdetail.subdomain, usertype: obj.userdetail.userType, role: obj.userdetail.roles, user_id: obj.userdetail.id }
        console.log("BBBBB", data)
        fetch(BASE_API_URL + "/MultiTenant_Get_Menu_two/",
          {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + access_token // Replace YOUR_AUTH_TOKEN with the actual token
            },
            body: JSON.stringify(data)
          })
          .then(response => response.json())
          .then(json => {
            console.log(json)
            if (json.detail) {
              Setdetail(json.code)
            } else {
              SetMenus(json)
              console.log("this is a Menus w", json)
              localStorage.setItem("Menus", JSON.stringify({
                Menus: json,
              }))
            }
          })
      }

    }
  }

  useEffect(() => {
    getMenus()
  }, [])

  return (
    <>

      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      /> */}


      {/* <AsideMenuItem
        to='/Configuration'
        // icon='/media/icons/duotune/general/gen019.svg'
        title='Configuration'
        // fontIcon='bi-layers'
      /> */}

      {/* <AsideMenuItem
        to='/other'
        icon='/media/icons/duotune/general/gen019.svg'
        title='other'
        fontIcon='bi-layers'
      /> */}

      <div className='text-white'>{detail}</div>
      <div className='menu-item' style={{ textTransform: "capitalize" }}>

        <div className='menu-content pt-8 pb-2'>
          {Menus.map((menu, index1) => (
            <div className='mb-4' key={index1}>
              {Tenant === "Tenant" ?
                <>
                  <AsideMenuItemWithSub to={menu['link']} title={menu['manuname']} fontIcon='bi-archive' icon={(menu['icon'] as string).replace(/^"|"$/g, '')}>
                    <ul className='ps-0 sub-side'>
                      {(JSON.parse((menu['submenu'] as string).replace(/'/g, '"'))).map((submenu: any, index2: any) => (
                        <li key={index2}>
                          <AsideMenuItem to={submenu['link']} title={submenu['SubMenu']} />
                        </li>
                      ))}
                    </ul>
                  </AsideMenuItemWithSub>

                </>
                :
                <li> {menu['permisstion'] == "1" ?
                  <AsideMenuItemWithSub to={menu['link']} title={menu['manuname']} fontIcon='bi-archive' icon={(menu['icon'] as string).replace(/^"|"$/g, '')} >
                    <ul>
                      {(JSON.parse((menu['submenu'] as any).replace(/'/g, '"'))).map((submenu: any, index3: any) => (
                        <div key={index3}>
                          <AsideMenuItem to={submenu['link']} title={submenu['SubMenu']} />
                        </div>
                      ))}
                    </ul>

                  </AsideMenuItemWithSub>
                  :
                  ""
                }
                </li>
              }
            </div>
            // <li className="mt-4"> {manu['display']?"":<Link to={manu['link']}> {manu['manuname']} </Link>} </li>
          ))}
          {/* <li className='menu-section text-muted text-uppercase fs-8 ls-1' ><Link to="/Configuration">dashboard</Link></li> */}
          {/* <li className='menu-section text-muted text-uppercase fs-8 ls-1'>user</li> */}
          {/* <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span> */}
        </div>
      </div>
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          {Menus.map(menu => (
            <li> {menu['display'] ?
              <AsideMenuItemWithSub to={menu['link']} title={menu['manuname']} fontIcon='bi-archive' icon={menu['icon']} >
                <ul>{console.log(menu['submenu'])}
                  {([menu['submenu']] as string[]).map(submenu => (
                    <AsideMenuItem to={menu['link']} title={menu['manuname']} hasBullet={true} />
                  ))}</ul>

              </AsideMenuItemWithSub>
              :
              ""
            }
            </li>
            // <li className="mt-4"> {manu['display']?"":<Link to={manu['link']}> {manu['manuname']} </Link>} </li>
          ))}
        </div>
      </div> */}


      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
