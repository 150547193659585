// src/components/List.tsx

import React, { FC, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'

const Backbuttonlink: FC = () => {
  let history = useHistory();

  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [isSuperUser, setIsSuperUser] = useState(false);
  const [showlinklist, setshowlinklist] = useState(false)
  const [submenulist, setsubmenulist] = useState([])
  const [usertype, setusertype] = useState('')
  useEffect(() => {
    get_SubMenu()
    get_role_permission("admin")
  }, [])

  function get_SubMenu() {
    var obj = JSON.parse(localStorage.getItem("login") || '{}')
    try {
      var data = { domain: obj.userdetail.subdomain }
      fetch(BASE_API_URL + "/MultiTenant_Get_SubMenu/",
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setsubmenulist(result)
        })
    } catch (error) {
      console.error("Failed to parse login data from localStorage", error);
    }
  }
  const [permissions, setPermissions] = useState<Record<string, string>>({});
  function get_role_permission(role: any) {
    var obj = JSON.parse(localStorage.getItem("login") || '{}')

    const userDetail = obj.userdetail || {};
    setusertype(userDetail.userType)
    const data = userDetail.userType === "Tenant"
      ? { domain: userDetail.subdomain, role: "admin" }
      : { domain: userDetail.subdomain, role: userDetail.roles.join('') };
    fetch(BASE_API_URL + "/MultiTenant_Get_Role_Permissions/",
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        setPermissions(json)
        console.log("get_role_permission", json)
      })
  }

  const linkClick = (link: string) => {
    history.push(link);
  };
  return (
    <div style={{ position: 'fixed', right: '1%', zIndex: 11, flexDirection: 'column', top: '37%', }}>
      <div
        id='kt_SuperUserOnly_toggle'
        style={{ marginTop: '19rem', position: 'absolute', width: 'max-content' }}
        className='superUserBtn btn btn-sm btn-white btn-active-primary shadow-sm fw-bolder end-0 rounded-top-0'
        onClick={(e) => setshowlinklist(!showlinklist)}
      >
        <span > {"<"} </span>
      </div>
      {showlinklist && (
        <div style={{ marginTop: '22rem', position: 'absolute', width: 'max-content' }}
          className='bg-white fw-bolder  end-0 rounded-top-0'>
          {/* <div className='border border-primary mb-1 rounded px-2 cursor-pointer active-secondary text-primary'>
            <Link to={'/crafted/account'} >
              Account
            </Link>
          </div> */}

          {submenulist.map((sbmenu) => (
            <>
              {usertype === "Tenant" ?
                <div className='border border-primary mb-1 rounded px-2 cursor-pointer active-secondary text-primary' onClick={() => linkClick(sbmenu['link'])}>
                  {sbmenu["SubMenu"]}
                </div>
                :
                <>
                  {permissions[sbmenu["SubMenu"]] === "1" && (
                    <div className='border border-primary mb-1 rounded px-2 cursor-pointer active-secondary text-primary' onClick={() => linkClick(sbmenu['link'])}>
                      {sbmenu["SubMenu"]}
                    </div>
                  )}
                </>
              }


            </>

          ))}


        </div>
      )}

    </div>
  );
};

export { Backbuttonlink };
