/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../helpers';
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { date } from 'yup';
import { Chart_table } from './Chart_table';
 
type Props = {
  className: string;
  slotname: any[];
  slotcount: any[];
  ptre_TopSlotCount: any[];
  loader: boolean;
}
 
const exportTableToExcel = (slotname: string[], slotcount: number[], ptre_TopSlotCount: number[]) => {
  const tableRows = [];
  const headerRow = ['Name', 'Count', 'Previous Slot Count', 'Total Change'];
  tableRows.push(headerRow.join(','));
  for (let i = 0; i < slotname.length; i++) {
    let totalChange = ptre_TopSlotCount[i] - slotcount[i];
    let totalChangeSymbol = totalChange >= 0 ? '-' : '+';
    const rowData = [
      slotname[i],
      slotcount[i],
      ptre_TopSlotCount[i],
      totalChangeSymbol + Math.abs(totalChange)
    ];
    tableRows.push(rowData.join(','));
  }
  const csvData = tableRows.join('\n');
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'Table.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
 
 
const Top_Slots: React.FC<Props> = ({ className, slotname, slotcount, ptre_TopSlotCount, loader }) => {
 
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 8;
 
  const sortedIndices = slotname
    .map((_, index) => index)
    .sort((a, b) => {
      const comparison = sortOrder === 'asc' ? slotname[a].localeCompare(slotname[b]) : slotname[b].localeCompare(slotname[a]);
      return comparison !== 0 ? comparison : a - b;
    });
 
  // Filtered and paginated data
  const filteredIndices = sortedIndices.filter(index =>
    slotname[index].toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(filteredIndices.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentIndices = filteredIndices.slice(indexOfFirstItem, indexOfLastItem);
 
  // Change page
  const paginate = (pageNumber: number): void => setCurrentPage(pageNumber);
 
  // Handle search term change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1); // Reset to first page
    setSearchTerm(event.target.value);
  };
 
  // Toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };
 
 
  // Calculate total number of pages
 
  // Generate page numbers
  const pageNumbers = [];
  for (let i = Math.max(1, currentPage - 1); i <= Math.min(currentPage + 1, totalPages); i++) {
    pageNumbers.push(i);
  }
 
  interface ChartData {
    actns: string[];
    cnts: number[];
    pre_slotcount: number[];
  }
 
 
  useEffect(() => {
    setLoading(loader);
 
    if (!chartRef.current || !slotname || !slotcount || !ptre_TopSlotCount) {
      return;
    }
 
    const height = parseInt(getCSS(chartRef.current, 'height'));
    const top7names = slotname.slice(0, 7);
    const top7Counts = slotcount.slice(0, 7);
    const top7pre_slotcount = ptre_TopSlotCount.slice(0, 7);
 
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, top7names, top7Counts, top7pre_slotcount));
 
    chart.render();
 
    return () => {
      chart.destroy();
    };
  }, [className, slotname, slotcount, ptre_TopSlotCount, loader]);
 
  interface ChartData {
    actns: string[];
    cnts: number[];
    pre_slotcount: number[];
  }
 
  interface ChartDataTableProps {
    chartData: ChartData;
  }
 
 
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      {loading && <div className="chart-loader d-flex flex-column-reverse">
        <div className='d-flex row-reverse m-0 justify-content-between align-items-end px-3'>
          <div className='custom-card-icon w-25px h-25px'></div>
          <div className='d-flex flex-column w-75 align-items-end'>
            <div className="custom-card-header w-25"></div>
            <div className="custom-card-header"></div>
          </div>
        </div>
        <div className='d-flex row m-0 justify-content-between h-100'>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
          <div className="chart-bar"></div>
        </div>
      </div>}
      <div className='card-header border-0 pt-3 m-0'>
        <h3 className='card-title align-items-start flex-column w-100'>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className='card-label fw-bolder fs-3 mb-1'>Number of Top Slots
            </span>
            <button className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-bs-toggle="dropdown">
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-left shadow" x-placement="top-left">
              <div className="menu-item px-3">
                <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                </div>
              </div>
 
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-2 text-gray-700 bg-hover-light-primary rounded text-hover-primary" data-bs-toggle="modal" data-bs-target="#viewTable">
                  <i className="bi-eye fs-5 me-3 text-gray-700"></i> View Data
                </a>
              </div>
              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link px-2 text-gray-700 bg-hover-light-success rounded text-hover-success"
                  // onClick={() => downloadchart()}
                  download="chart.png"
                >
                  <i className="bi-download fs-5 me-3 text-gray-700"></i>Export Chart
                </a>
              </div>
            </div>
          </div>
          <span className='text-muted fw-bold fs-7'>Top Slots</span>
        </h3>
        {/* begin::Toolbar */}
        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >
            Year
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_week_btn'
          >
            Week
          </a>
        </div> */}
        {/* end::Toolbar */}
      </div >
      {/* end::Header */}
 
      {/* begin::Body */}
      <div className='card-body p-5'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '400px' }}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
      <div className="modal fade" id="viewTable" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-full" role="document">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Number of top Slots</h5>
              <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
                <Chart_table className={className} chartDataname={slotname} dataValue={slotcount} preDataValue={ptre_TopSlotCount} loader={loader}
              />
          </div>
        </div>
      </div>
    </div >
  )
}
export { Top_Slots }
 
function getChartOptions(height: number, actns: any, cnts: any, pre_slotcount: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-light-info')
  const secondaryColor = getCSSVariableValue('--bs-success')
 
  return {
    series: [
      {
        name: 'Current',
        data: cnts,
      },
      {
        name: 'From the last same days',
        data: pre_slotcount,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: actns,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' Users'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}