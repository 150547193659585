/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../helpers';
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { date } from 'yup';

 
type Props = {
  className: string;
  chartDataname: any[];
  dataValue: any[];
  preDataValue: any[];
  loader: boolean;
}
 
const exportTableToExcel = (chartDataname: string[], dataValue: number[], preDataValue: number[]) => {
  const tableRows = [];
  const headerRow = ['Name', 'Count', 'Previous Slot Count', 'Total Change'];
  tableRows.push(headerRow.join(','));
  for (let i = 0; i < chartDataname.length; i++) {
    let totalChange = preDataValue[i] - dataValue[i];
    let totalChangeSymbol = totalChange >= 0 ? '-' : '+';
    const rowData = [
      chartDataname[i],
      dataValue[i],
      preDataValue[i],
      totalChangeSymbol + Math.abs(totalChange)
    ];
    tableRows.push(rowData.join(','));
  }
  const csvData = tableRows.join('\n');
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'Table.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
 
 
const Chart_table: React.FC<Props> = ({ className, chartDataname, dataValue, preDataValue, loader }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 8;
 
  const sortedIndices = chartDataname
    .map((_, index) => index)
    .sort((a, b) => {
      const comparison = sortOrder === 'asc' ? chartDataname[a].localeCompare(chartDataname[b]) : chartDataname[b].localeCompare(chartDataname[a]);
      return comparison !== 0 ? comparison : a - b;
    });
 
  // Filtered and paginated data
  const filteredIndices = sortedIndices.filter(index =>
    chartDataname[index].toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(filteredIndices.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentIndices = filteredIndices.slice(indexOfFirstItem, indexOfLastItem);
 
  // Change page
  const paginate = (pageNumber: number): void => setCurrentPage(pageNumber);
 
  // Handle search term change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1); // Reset to first page
    setSearchTerm(event.target.value);
  };
 
  // Toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };
 
 
  // Calculate total number of pages
 
  // Generate page numbers
  const pageNumbers = [];
  for (let i = Math.max(1, currentPage - 1); i <= Math.min(currentPage + 1, totalPages); i++) {
    pageNumbers.push(i);
  }
 
 
  return (
          <div className="">
            <div className="modal-body">
              <div>
                <div className='w-100 d-flex justify-content-between align-items-center mb-4'>
                  <form className='position-relative' autoComplete='off'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid px-15 w-auto'
                      onChange={handleSearchChange}
                      name='search'
                      placeholder='Search by User Name'
                      autoComplete='off'
                    />
                  </form>
                  <button className='btn btn-sm btn-light-primary' onClick={() => exportTableToExcel(chartDataname, dataValue, preDataValue)}><i className="bi-download me-2"></i>Export Table</button>
                </div>
                <table className='table table-hover table-rounded border gy-7 gs-7'>
                  <thead>
                    <tr>
                      <th>
                        <div className='cursor-pointer' onClick={toggleSortOrder}>Name {sortOrder === 'asc' ? <i className="bi bi-caret-up-fill"></i> : <i className="bi bi-caret-down-fill"></i>}</div></th>
                      <th>Count</th>
                      <th>Previous Slot Count</th>
                      <th>Total Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentIndices.map((index) => (
                      <tr key={index}>
                        <td>{chartDataname[index]}</td>
                        <td>{dataValue[index]}</td>
                        <td>{preDataValue[index]}</td>
                        <td>
                          {preDataValue[index] !== dataValue[index] && (
                            <>
                              {dataValue[index] - preDataValue[index] > 0 ? (
                                <i className="bi-arrow-up text-success me-1"></i>
                              ) : (
                                <i className="bi-arrow-down text-danger me-1"></i>
                              )}
                            </>
                          )}
                          {Math.abs(preDataValue[index] - dataValue[index])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer px-5'>
              <nav>
                <ul className="pagination">
                  {/* Previous button */}
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                  </li>
                  {/* Page numbers */}
                  {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => paginate(number)}>{number}</button>
                    </li>
                  ))}
                  {/* Next button */}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
  )
}
export { Chart_table }
 
